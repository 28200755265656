<script setup lang="ts">
import type { ProductAlgolia } from "utils/mapProducts"
import type { ComponentRecapordercartProps } from "./ComponentRecapordercart.props"
import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"
import { OrganismKeepBuyingProps } from "../ProductBlocks/KeepBuying/OrganismKeepBuying.props"
import { connect as connectImage } from "~/connectors/fragments/WrapperImage"
import { mapInfoDeliveryOrder } from "~/utils/orders"
import { customPaths } from "~/utils/constants"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"

const props = defineProps<ComponentRecapordercartProps>()
const { $cl } = useNuxtApp()
const { t } = useI18n()
const { lineItems } = await useCart()
const isLogged = useIsLogged()
const userStore = useUserStore()
const { userId } = storeToRefs(userStore)
const { get } = useAlgoliaRecommend("recap-order")
const dayjs = useDayjs()

const { data: lastOrders } = useLazyAsyncData(
  "ComponentRecapordercartlastOrders",
  async () =>
    isLogged.value
      ? await $cl.customers.orders(userId.value, {
          include: [
            "line_items",
            "line_items.item",
            "shipments",
            "returns",
            "shipping_address",
            "refunds",
            "shipments.parcels"
          ],
          filters: {
            status_not_in: "draft,pending"
          },
          sort: { placed_at: "desc" },
          pageSize: 10
        })
      : null,
  {
    server: false,
    immediate: false,
    watch: [userId]
  }
)

const lastTwoOrdersCompleted = computed(
  () =>
    lastOrders.value &&
    _take(
      [...lastOrders.value].filter((order) => {
        const shipment = order.shipments?.at(-1)
        const parcel = shipment?.parcels
          ? shipment?.parcels.find((parcel) => parcel.tracking_status)
          : null
        if (
          order.status === "placed"
          // in case we should need to show only delivered orders
          // order.status === "approved" && parcel?.tracking_status === "delivered"
        ) {
          return true
        }
        return false
      }),
      2
    )
)

const lastPlacedOrder = computed(
  () =>
    lastOrders.value &&
    [...lastOrders.value].find((order) => order.status === "placed")
)

const lastTwoProducts = computed(() =>
  lineItems.value && lineItems.value.length > 0
    ? lineItems.value
        .filter((item, index) => index <= 1)
        .map((product) => {
          const discount = discountPercentage(
            product?.metadata?.price,
            product?.metadata?.oldPrice
          )
          return {
            productImage: connectImage(product?.metadata?.product_image),
            productCode: product.sku_code,
            title: product.name,
            oldPrice: product?.metadata?.oldPrice ?? 0,
            currentPrice: product?.metadata?.price ?? 0,
            quantity: product.quantity,
            path: `${product?.metadata?.slug ?? "#"}`,
            expirationDate: product?.metadata?.expirationDate,
            inStock: product?.metadata?.inStock,
            isDeductable: product?.metadata?.isDeductable,
            unitType: product?.metadata?.unitType,
            longVersion: true,
            promotions: [{ theme: "SCONTO", text: `-${discount}%` }]
          }
        })
    : []
)

const getRelatedProducts = async () => {
  return await get({
    queries: [...lastTwoProducts.value].map((item) => ({
      indexName: `TOP_PRODUCTS${checkEnvSite}`,
      model: "related-products",
      objectID: `product_${item.productCode}`,
      maxRecommendations: 2,
      threshold: 10
    }))
  }).then(({ results }) => {
    const products: ProductAlgolia[] =
      _flattenDeep(results?.map((item) => item?.hits))?.filter(
        (item, index) => !!item && index <= 2
      ) ?? []
    return mapProductsAlgolia(products)
  })
}

const suggestionProducts = ref()

// @ts-ignore
const keepBuyingCartBox: ComputedRef<null | OrganismKeepBuyingProps> = computed(
  () => ({
    keepBuying: {
      text: t("productBlocks.keepBuying.text"),
      products: lastTwoProducts.value ?? [],
      path: customPaths.cart
    },
    suggestions: {
      products: suggestionProducts.value ?? []
    }
  })
)

const trackingOrderBox = computed(() => {
  if (isLogged.value && props.type === "orderRecap") {
    // @ts-ignore
    const scheduledDelivery = mapInfoDeliveryOrder(
      lastPlacedOrder.value?.shipments.at(-1)
    )
    const infoAddressLastOrder = lastPlacedOrder.value?.shipping_address ?? null
    return {
      trackingOrder: {
        orderNumber: lastPlacedOrder.value?.reference ?? "NOT FOUND",
        orderDate: dayjs(lastPlacedOrder.value?.created_at).format(
          "dddd DD MMMM"
        ),
        scheduledDeliveryDate: scheduledDelivery,
        shipmentAddress: infoAddressLastOrder
          ? `${infoAddressLastOrder.line_1}\n ${infoAddressLastOrder.city}, ${infoAddressLastOrder.state_code} ${infoAddressLastOrder.zip_code}`
          : "",
        path: `${customPaths.profile.order}/${lastPlacedOrder.value?.id}`
      },
      lastOrders: {
        title: t("card.myOrder.buyAgain"),
        orders:
          lastTwoOrdersCompleted.value &&
          lastTwoOrdersCompleted.value.map((order) => {
            const productMapped =
              order.line_items && order.line_items?.length > 0
                ? order.line_items
                    .filter((item) => item.sku_code)
                    .map((product) => ({
                      productImage: connectImage(
                        product?.metadata?.product_image
                      ),
                      skuCode: product.sku_code,
                      title: product.name,
                      oldPrice: product?.metadata?.oldPrice ?? 0,
                      currentPrice: product?.metadata?.price ?? 0,
                      quantity: product.quantity,
                      path: `${product?.metadata?.slug ?? "#"}`,
                      expirationDate: product?.metadata?.expirationDate,
                      inStock: product?.metadata?.inStock,
                      isDeductable: product?.metadata?.isDeductable,
                      unitType: product?.metadata?.unitType,
                      fullWidth: true
                    }))
                : []
            const parcel = order.shipments
              ?.at(-1)
              ?.parcels?.find((parcel) => parcel.tracking_status)
            return {
              deliveryDate: parcel
                ? dayjs(parcel.tracking_status_updated_at).format("D MMMM YYYY")
                : "",
              statusText: t("productBlocks.purchaseAgain.placedOn", {
                date: dayjs(order.created_at).format("D MMMM YYYY")
              }),
              products: productMapped
            }
          }),
        theme: "white"
      }
    }
  }
})

onMounted(() => {
  setTimeout(async () => {
    suggestionProducts.value = await getRelatedProducts()
  }, 500)
})
</script>

<template>
  <div class="cms-order-or-cart-recap padded bg-tiffany-10">
    <ProductBlocksKeepBuyingOrganismKeepBuying
      v-if="props.type === 'cartRecap' && suggestionProducts"
      v-bind="keepBuyingCartBox"
    />
    <ProductBlocksOrganismTrackingOrder
      v-if="
        props.type === 'orderRecap' &&
        isLogged &&
        lastPlacedOrder &&
        lastTwoOrdersCompleted &&
        lastTwoOrdersCompleted.length > 0
      "
      v-bind="trackingOrderBox"
    />
  </div>
</template>
