<script lang="ts" setup>
import { OrganismKeepBuyingProps } from "~/components/ProductBlocks/KeepBuying/OrganismKeepBuying.props"

defineProps<OrganismKeepBuyingProps>()

const emit = defineEmits<{ (e: "onNotifyMe"): void }>()
</script>

<template>
  <div
    class="
      keep-buying__content
      flex flex-col
      bg-tiffany-10
      px-2
      py-6
      md:flex-row
      md:space-x-6
      md:[&>*]:flex-1
    "
  >
    <ProductBlocksKeepBuyingUtilsBox v-if="keepBuying" :="keepBuying" />

    <ProductBlocksKeepBuyingUtilsSuggestionsBox
      :="suggestions"
      @onNotifyMe="emit('onNotifyMe')"
    />
  </div>
</template>
