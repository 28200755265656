<script lang="ts" setup>
import { SuggestionsBoxProps } from "~/components/ProductBlocks/KeepBuying/Utils/SuggestionsBox.props"

defineProps<SuggestionsBoxProps>()
const emit = defineEmits<{ (e: "onNotifyMe"): void }>()
</script>

<template>
  <div
    class="
      suggestions-box
      space-y-6
      bg-tiffany-10
      px-4
      py-6
      md:space-y-4
      md:p-8
      md:shadow-01
    "
  >
    <header class="space-y-4">
      <p class="hyppo-bold md:elephant-bold text-green-main">
        {{ $t("productBlocks.keepBuying.utils.suggestionsBox.weRecommend") }}
      </p>
    </header>
    <!-- TODO: Find a way to do this v-if check inside the component, instead of each instance -->
    <GAItem
      v-if="products.length"
      id="products-based-on-orders"
      :item="products"
    >
      <GAViewItemList method="carrello"> </GAViewItemList>
    </GAItem>

    <div class="space-y-3 md:space-y-4">
      <ProductTileOverallSearch
        v-for="product in products"
        :key="product.title"
        :="product"
        long-version
        @onNotifyMe="emit('onNotifyMe')"
      />
    </div>
  </div>
</template>
